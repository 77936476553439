import DigitalOutput from '../components/Devices_Config_Image/led.png';
import DigitalInput from '../components/Devices_Config_Image/light-switch.png';
import AnalogInput from '../components/Devices_Config_Image/radio-waves.png';
import AnalogOutput from '../components/Devices_Config_Image/digitalanalog(2).png';
import PWM from '../components/Devices_Config_Image/pulse.png';
import UART from '../components/Devices_Config_Image/vga-cable.png';
import OneWire from '../components/Devices_Config_Image/wiring.png';
import I2C from '../components/Devices_Config_Image/I2C_bus.png';
import SPI from '../components/Devices_Config_Image/spi.png';
import Other from '../components/Devices_Config_Image/transistor.png';

const styles = {
  icon: {
    width: '28px',
    height: '28px',
    objectFit: 'contain',
  },
};
export const SUPPORTED_DEVICES = [
  { value: 1, isAvailabe: true, name: 'Digital Output', icon: <img src={DigitalOutput} alt="DigitalOutput" style={styles.icon} /> },
  { value: 2, isAvailabe: true, name: 'Digital Input', icon: <img src={DigitalInput} alt="DigitalInput" style={styles.icon} /> },
  { value: 3, isAvailabe: true, name: 'Analog Input', icon: <img src={AnalogInput} alt="AnalogInput" style={styles.icon} /> }, // (ADC)
  { value: 4, isAvailabe: false, name: 'Analog Output', icon: <img src={AnalogOutput} alt="AnalogOutput" style={styles.icon} /> }, // (DAC)
  { value: 5, isAvailabe: true, name: 'PWM', icon: <img src={PWM} alt="PWM" style={styles.icon} /> },
  { value: 6, isAvailabe: true, name: 'UART', icon: <img src={UART} alt="UART" style={styles.icon} /> },
  { value: 7, isAvailabe: true, name: 'One Wire', icon: <img src={OneWire} alt="OneWire" style={styles.icon}/> },
  { value: 8, isAvailabe: true, name: 'I2C', icon: <img src={I2C} alt="I2C" style={styles.icon} /> },
  { value: 9, isAvailabe: false, name: 'SPI', icon: <img src={SPI} alt="SPI" style={styles.icon} /> },
  { value: 10, isAvailabe: true, name: 'Other', icon: <img src={Other} alt="Other" style={styles.icon} /> }
];

export const ID_ITEMS = [
  { id_item: 'LED', value: 1, dev_type: 1, dev_name: 'Digital Output ' },
  { id_item: 'Relay', value: 2, dev_type: 1, dev_name: 'Digital Output ' },
  { id_item: 'Plug', value: 3, dev_type: 1, dev_name: 'Digital Output ' },
  {
    id_item: 'Switch / Push Button / Key',
    value: 4,
    dev_type: 2,
    dev_name: 'Digital Input'
  },
  {
    id_item: 'MPU6050 Accelerometer and Gyroscope',
    value: 5,
    dev_type: 8,
    dev_name: 'I2C'
  },
  {
    id_item: 'ADXL345 3-Axis Accelerometer',
    value: 6,
    dev_type: 8,
    dev_name: 'I2C'
  },
  {
    id_item: 'GY-271 Magnetometer',
    value: 7,
    dev_type: 8,
    dev_name: 'I2C'
  },
  {
    id_item: 'LTR303ALS Luminosity sensor',
    value: 8,
    dev_type: 8,
    dev_name: 'I2C'
  },
  {
    id_item: 'RMT SK6812',
    value: 9,
    dev_type: 10,
    dev_name: 'Other'
  },
  {
    id_item: 'BMP680 I2C Sensor',
    value: 10,
    dev_type: 8,
    dev_name: 'I2C'
  },
  {
    id_item: 'BMP280 I2C Sensor',
    value: 12,
    dev_type: 8,
    dev_name: 'I2C'
  },
  {
    id_item: 'DHT11 Temperature Humidity Sensor',
    value: 15,
    dev_type: 7,
    dev_name: 'One wire'
  },
  {
    id_item: 'DHT22 Temperature Humidity Sensor',
    value: 16,
    dev_type: 7,
    dev_name: 'One wire'
  },
  {
    id_item: 'Potentiometer',
    value: 17,
    dev_type: 3,
    dev_name: 'Analog Input'
  },
  {
    id_item: 'Internal Hall Effect Sensor',
    value: 18,
    dev_type: 10,
    dev_name: 'Other'
  },
  {
    id_item: 'PIR Sensor',
    value: 19,
    dev_type: 2,
    dev_name: 'Digital Input'
  },
  {
    id_item: 'Parallax 2-Axis Joystick',
    value: 20,
    dev_type: 10,
    dev_name: 'Other'
  },
  {
    id_item: 'MB1013 Sonar',
    value: 21,
    dev_type: 6,
    dev_name: 'UART'
  },
  {
    id_item: 'PWM Generic Load',
    value: 22,
    dev_type: 5,
    dev_name: 'PWM'
  },
  {
    id_item: 'Touch Switch TTP223B (Toggle)',
    value: 23,
    dev_type: 2,
    dev_name: 'Digital Input'
  },
  {
    id_item: 'Ultrasonic Sensor HC SR04',
    value: 24,
    dev_type: 10,
    dev_name: 'Other'
  },
  {
    id_item: 'LDR Digtial Sensor',
    value: 25,
    dev_type: 2,
    dev_name: 'Digital Input'
  },
  {
    id_item: 'LDR analog Sensor',
    value: 26,
    dev_type: 3,
    dev_name: 'Analog Input'
  },
  {
    id_item: 'Water Leak Sensor',
    value: 27,
    dev_type: 3,
    dev_name: 'Analog Input'
  },
  {
    id_item: 'GY61 3-Axis Accelerometer',
    value: 28,
    dev_type: 10,
    dev_name: 'Other'
  },
  {
    id_item: 'GXHTC3 - RH and T Sensor',
    value: 29,
    dev_type: 8,
    dev_name: 'I2C'
  },
  {
    id_item: 'DS18B20 Temperature Sensor',
    value: 30,
    dev_type: 7,
    dev_name: 'One wire'
  },
  {
    id_item: 'JSN SR04T Water Level Sensor',
    value: 31,
    dev_type: 10,
    dev_name: 'Other'
  },
  {
    id_item: 'Soil Moisture Sensor',
    value: 32,
    dev_type: 3,
    dev_name: 'Analog Input'
  },
  {
    id_item: 'Turbidity Sensor',
    value: 33,
    dev_type: 3,
    dev_name: 'Analog Input'
  },
  {
    id_item: 'Proximity Sensor',
    value: 34,
    dev_type: 2,
    dev_name: 'Digital Input'
  },
  {
    id_item: 'Touch switch TPP 223B (Momentary)',
    value: 35,
    dev_type: 2,
    dev_name: 'Digital Input'
  },
  {
    id_item: 'Servo Motor MG996R',
    value: 36,
    dev_type: 5,
    dev_name: 'PWM'
  },
  {
    id_item: 'SK6812 RGB LEDs',
    value: 38,
    dev_type: 10,
    dev_name: 'Other'
  },
  {
    id_item: 'TCS230 Sensor',
    value: 40,
    dev_type: 5,
    dev_name: 'PWM'
  },
  {
    id_item: 'FC28 Soil Moisture Sensor',
    value: 41,
    dev_type: 3,
    dev_name: 'Analog Input'
  },
  {
    id_item: 'Shunt voltmeter',
    value: 42,
    dev_type: 3,
    dev_name: 'Analog Input'
  },
  {
    id_item: 'UV Intensity Sensor',
    value: 43,
    dev_type: 3,
    dev_name: 'Analog Input'
  },
  {
    id_item: 'TSL2561 Luminosity Sensor',
    value: 44,
    dev_type: 8,
    dev_name: 'I2C'
  },
  {
    id_item: 'CS712TELC 05B Current Sensor',
    value: 46,
    dev_type: 3,
    dev_name: 'Analog Input'
  },
  {
    id_item: 'HX711 LOADCELL',
    value: 47,
    dev_type: 10,
    dev_name: 'Other'
  },
  {
    id_item: 'MQ4 Methane Gas Sensor',
    value: 48,
    dev_type: 10,
    dev_name: 'Other'
  },
  {
    id_item: 'MQ2 LPG Gas Sensor',
    value: 49,
    dev_type: 10,
    dev_name: 'Other'
  },
  {
    id_item: 'MQ3 Alcohol Sensor',
    value: 50,
    dev_type: 10,
    dev_name: 'Other'
  },
  {
    id_item: 'MQ8 H2 Gas Sensor',
    value: 51,
    dev_type: 10,
    dev_name: 'Other'
  },
  {
    id_item: 'MQ135 NH3 Gas Sensor',
    value: 52,
    dev_type: 10,
    dev_name: 'Other'
  },
  {
    id_item: 'GPS GYGPS6MV2',
    value: 53,
    dev_type: 6,
    dev_name: 'UART'
  },
  {
    id_item: 'YFS201 flowmeter',
    value: 54,
    dev_type: 5,
    dev_name: 'PWM'
  },
  {
    id_item: 'Flex Resistor',
    value: 55,
    dev_type: 3,
    dev_name: 'Analog Input'
  },
  {
    id_item: 'Force Sensitive Resistor',
    value: 56,
    dev_type: 3,
    dev_name: 'Analog Input'
  },
  {
    id_item: 'KY026 Flame Sensor',
    value: 57,
    dev_type: 10,
    dev_name: 'Other'
  },
  {
    id_item: 'MQ6 LPG Detector',
    value: 59,
    dev_type: 10,
    dev_name: 'Other'
  },
  {
    id_item: 'Vibration Sensor',
    value: 60,
    dev_type: 2,
    dev_name: 'Digital Input'
  },
  {
    id_item: 'Reed Switch',
    value: 61,
    dev_type: 2,
    dev_name: 'Digital Input'
  },
  {
    id_item: 'MQ7 CO Detector',
    value: 62,
    dev_type: 10,
    dev_name: 'Other'
  },
  {
    id_item: 'MQ9 LPG Detector',
    value: 63,
    dev_type: 10,
    dev_name: 'Other'
  },
  {
    id_item: 'Float Switch',
    value: 65,
    dev_type: 2,
    dev_name: 'Digital Input'
  },
  {
    id_item: 'R307 Fingerprint Module',
    value: 66,
    dev_type: 10,
    dev_name: 'Other'
  },

];

export const devSubTypes = ['LED', 'RELAY', 'PLUG'];
export const DEFAULT = [
  {
    label: 'LOW',
    value: 'low'
  },
  {
    label: 'HIGH',
    value: 'high'
  }
];
export const RESOLUTIONS = [
  {
    label: '8-bit',
    value: 8
  },
  {
    label: '10-bit',
    value: 10
  },
  {
    label: '12-bit',
    value: 12
  }
];

export const RESOLUTIONSPWM = [
  {
    label: '8-bit',
    value: 8
  },
  {
    label: '9-bit',
    value: 9
  },
  {
    label: '10-bit',
    value: 10
  },
  {
    label: '11-bit',
    value: 11
  },
  {
    label: '12-bit',
    value: 12
  },
  {
    label: '13-bit',
    value: 13
  },
  {
    label: '14-bit',
    value: 14
  }
];

export const DHT = ['DHT11', 'DHT22'];
export const ID_ITEM = [
  'MPU605',
  'ADXL345',
  'GY271',
  'MCP4725',
  'GY530',
  'DS1307',
  'MAX30100',
  'BMP280',
  'LNA219'
];

export const GPIO_KEYS = [
  'gpio',
  'gpio_out',
  'gpio_in',
  'gpio_miso',
  'gpio_cs',
  'gpio_sck',
  'gpio_mosi',
  'gpio_scl',
  'gpio_sda'
];

export const BAUDRATE = [
  4800, 9600, 14400, 19200, 38400, 57600, 115200, 128000, 256000
];

export const ANALOGINPUTESP32 = [
  32, 33, 34, 35, 36, 37, 38, 39
];

export const C12ESP32S3INPUT = [
  1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 26,
  27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45,
  46, 47, 48
];

export const C12ESP32INPUT = [
  4, 5, 13, 14, 15, 16, 17, 18, 19, 21, 22, 23, 25, 26, 27, 32, 33
];

export const ONEWIREESP32S3INPUT = [
  0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
  26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44,
  45, 46, 47, 48
];

export const DIGITALINPUTGPINPUTESP32 = [
  2, 4, 5, 13, 14, 15, 16, 17, 18, 19, 21, 22, 23, 25, 26, 27, 32, 33, 34, 35,
  36, 39
];

export const DIGITALINPUTGPINPUTEESP32C3GPOINPUT = [
  0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21
];

export const GPIO = [
  2, 4, 5, 13, 14, 15, 16, 17, 18, 19, 21, 22, 23, 25, 26, 27, 32, 33
];

export const GPIOINPUT = [
  4, 5, 13, 14, 15, 16, 17, 18, 19, 21, 22, 23, 25, 26, 27, 32, 33, 34, 35, 36,
  39
];

export const ESP32S3GPO = [
  0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
  26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44,
  45, 46, 47, 48
];

export const ESP32S3GPINPUT = [
  0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
  26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44,
  45, 46, 47, 48
];

export const ESP32C3GPO = [
  1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21
];

export const ESP32C3GPINPUT = [
  1, 2, 3, 4, 5, 6, 7, 8, 9, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21
];

//ESP32
// Digital Out
// PWM
// UART
// One Wire
// I2C
// SPI
// Other

export const ESP32PIN = [
  2, 4, 5, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 25, 26, 27, 32, 33
];

//ESP32S3
// Digital Out
// Digital Input
// PWM
// UART
// One Wire
// I2C
// SPI
// Other

export const ESP32S3PIN = [
  0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
  22, 23, 24, 25, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48
];

//ESP32C3
// Digital Out
// Digital Input
// PWM
// UART
// One Wire
// I2C
// SPI
// Other

export const ESP32C3PIN = [
  0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 18, 19, 20, 21
];

export const ANALOGOUTPUTESP32 = [
  25, 26
];

export const ANALOGOUTPUTESP32C3 = [

];

export const ANALOGOUTPUTESP32S3 = [

];

export const TOUCHESP32 = [
  2, 4, 12, 13, 14, 15, 25, 27, 32, 33
];

export const TOUCHESP32C3 = [

];

export const TOUCHESP32S3 = [
  1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14
];

export const DIGITALINPUTESP32 = [
  2, 3, 4, 5, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 25, 26, 27, 32, 33, 34, 35,
  36, 38, 39
];

export const DIGITALINPUTESP32C3 = [
  0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 18, 19, 20, 21
];

export const DIGITALINPUTESP32S3 = [
  0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25,
  33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48
];

export const ANALOGINPUTESP32C3 = [
  0, 1, 2, 3, 4
];

export const ANALOGINPUTESP32S3 = [
  1, 2, 3, 4, 5, 6, 7, 8, 9, 10
];