import React, { useContext,useState } from 'react';

import {
  ListSubheader,
  alpha,
  Box,
  List,
  styled,
  ButtonGroup,
  Button,
  ListItem,
  Select,
  MenuItem,
  FormControl,
  InputLabel
} from '@mui/material';
import { NavLink as RouterLink } from 'react-router-dom';
import { SidebarContext } from 'src/contexts/SidebarContext';
import SettingsBackupRestoreIcon from '@mui/icons-material/SettingsBackupRestore';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import BoltIcon from '@mui/icons-material/Bolt';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import InfoIcon from '@mui/icons-material/Info';
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';
import AutoDeleteIcon from '@mui/icons-material/AutoDelete';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import { useDispatch, useSelector } from 'react-redux';
import {formClose,
    deviceFormClose,
    deviceConfig,
    isConnected,
    isDisconnected,
    terminalStatus,
    firmwareType,
    isDeviceInfo,
    isFlash,
    isCoreFlash,
    isReset,
    isFactorReset,
    deviceGetConfig} from '../../../../redux/slices/devicesSlice';
import WifiOutlinedIcon from '@mui/icons-material/WifiOutlined';
import WifiOffOutlinedIcon from '@mui/icons-material/WifiOffOutlined';
import GppGoodIcon from '@mui/icons-material/GppGood';
import GppMaybeIcon from '@mui/icons-material/GppMaybe';
import Alert from "@mui/material/Alert";
import CircularProgress from "@mui/material/CircularProgress";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Switch from '@mui/material/Switch';
import TerminalIcon from '@mui/icons-material/Terminal';
import { useTheme } from '@mui/material/styles';

const MenuWrapper = styled(Box)(
  ({ theme }) => `
  .MuiList-root {
    padding: ${theme.spacing(0.5)} ${theme.spacing(0)};

    & > .MuiList-root {
      padding: 0 ${theme.spacing(0)} ${theme.spacing(0)};
    }
    .MuiListItem-root {
        padding: ${theme.spacing(0.3)} ${theme.spacing(1.5)};
    }
  }

    .MuiListSubheader-root {
      text-transform: uppercase;
      font-weight: bold;
      font-size: ${theme.typography.pxToRem(12)};
      color: ${theme.colors.alpha.trueWhite[50]};
      padding: ${theme.spacing(0, 2.5)};
      line-height: 1.4;
      text-align: center;
    }
`
);

const SubMenuWrapper = styled(Box)(
  ({ theme }) => `
    .MuiList-root {

      .MuiListItem-root {
        padding: 1px 0;

        .MuiBadge-root {
          position: absolute;
          right: ${theme.spacing(3.2)};

          .MuiBadge-standard {
            background: ${theme.colors.primary.main};
            font-size: ${theme.typography.pxToRem(10)};
            font-weight: bold;
            text-transform: uppercase;
            color: ${theme.palette.primary.contrastText};
          }
        }
    
        /*.MuiButton-root {
          display: flex;
          color: ${theme.colors.alpha.trueWhite[70]};
          /!*background-color: transparent;*!/
          width: 100%;
          justify-content: flex-start;
          padding: ${theme.spacing(0.5, 3)};

          .MuiButton-startIcon,
          .MuiButton-endIcon {
            transition: ${theme.transitions.create(['color'])};

            .MuiSvgIcon-root {
              font-size: inherit;
              transition: none;
            }
          }
           
          .MuiButton-startIcon {
            color: ${theme.colors.alpha.trueWhite[30]};
            font-size: ${theme.typography.pxToRem(20)};
            margin-right: ${theme.spacing(1)};
          }
          
          .MuiButton-endIcon {
            color: ${theme.colors.alpha.trueWhite[50]};
            margin-left: auto;
            opacity: .8;
            font-size: ${theme.typography.pxToRem(20)};
          }

          &.active,
          &:hover {
            /!*background-color: ${alpha(theme.colors.alpha.trueWhite[100], 0.06)};*!/
            color: ${theme.colors.alpha.trueWhite[100]};

            .MuiButton-startIcon,
            .MuiButton-endIcon {
              color: ${theme.colors.alpha.trueWhite[100]};
            }
          }
          .MuiPaper-root {
          padding: ${theme.spacing(0.2, 3)};
          }
        }*/
        .MuiButton-root {
            /*margin-left: 30px;*/
            color: ${theme.colors.alpha.trueWhite[100]};
            padding: ${theme.spacing(0.5, 3)};
            
          .MuiPaper-root {
            padding: ${theme.spacing(0.2, 3)};
          }
        }
        &.Mui-children {
          flex-direction: column;

          .MuiBadge-root {
            position: absolute;
            right: ${theme.spacing(7)};
          }
        }

        .MuiCollapse-root {
          width: 100%;

          .MuiList-root {
            padding: ${theme.spacing(1, 0)};
          }

          .MuiListItem-root {
            padding: 1px 0;

            .MuiButton-root {
              padding: ${theme.spacing(0.8, 3)};

              .MuiBadge-root {
                right: ${theme.spacing(3.2)};
              }

              &:before {
                content: ' ';
                background: ${theme.colors.alpha.trueWhite[100]};
                opacity: 0;
                transition: ${theme.transitions.create([
                  'transform',
                  'opacity'
                ])};
                width: 6px;
                height: 6px;
                transform: scale(0);
                transform-origin: center;
                border-radius: 20px;
                margin-right: ${theme.spacing(1.8)};
              }

              &.active,
              &:hover {

                &:before {
                  transform: scale(1);
                  opacity: 1;
                }
              }
            }
          }
        }
      }
    }
`
);

const MaterialUISwitch = styled(Switch)(({ theme }) => ({
    width: 62,
    height: 34,
    padding: 7,
    '& .MuiSwitch-switchBase': {
        margin: 1,
        padding: 0,
        transform: 'translateX(6px)',
        '&.Mui-checked': {
            color: '#fff',
            transform: 'translateX(22px)',
            '& + .MuiSwitch-track': {
                opacity: 1,
                backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#aab4be',
            },
        },
    },
    '& .MuiSwitch-thumb': {
        backgroundColor: theme.palette.mode === 'dark' ? '#003892' : '#001e3c',
        width: 32,
        height: 32,
        '&:before': {
            content: "''",
            position: 'absolute',
            width: '100%',
            height: '100%',
            left: 0,
            top: 0,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
        },
    },
    '& .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#aab4be',
        borderRadius: 20 / 2,
    },
}));
function SidebarMenu() {
    const theme = useTheme();
  const { closeSidebar } = useContext(SidebarContext);
    const dispatch = useDispatch();
    const ToggleForm = useSelector((state) => state['devicesData']);
    // console.log('🚀 ~ file: sidebarMenu.tsx:184 ~ SidebarMenu',ToggleForm);
    const [checked, setChecked] = useState(ToggleForm?.terminalStatus);
    const [firmware, setFirmware] = useState(ToggleForm?.firmwareType);
    const ezloFirmware = ToggleForm?.ezloFirmwareStatus;
    console.log('ezloFirmware',ezloFirmware);
    const deviceCMD = ToggleForm?.deviceCMD;
    console.log('deviceCMD',deviceCMD);
    const handleFirmwareChange = (event) => {
        setFirmware(event.target.value);
        dispatch(firmwareType(event.target.value));
    };
    const handleChange = (event) => {
        setChecked(event.target.checked);
        dispatch(terminalStatus(event.target.checked));
    };

    const flashButtonClick = () => {
        /*console.log('flash Button Click');*/
            dispatch(isFlash(true));
    };
  return (
    <>
      <MenuWrapper>
          <FormControl
              fullWidth
              sx={{
                  // Add padding as needed, for example:
                  padding: '10px', // Adds padding all around
                  '.MuiInputLabel-root': { // Target the label specifically
                      paddingLeft: '10px',// Adds padding to the left of the label
                      marginTop: '10px' // Adds padding to the left of the label
                  },
                  '.MuiSelect-select': { // Target the select input specifically
                      paddingLeft: '10px' // Adds padding to the left of the select input
                  },
                  marginTop: '10px'
              }}
          >
              <InputLabel
                  id="firmware-select-label"
                  sx={{
                      color: 'white', // Set the label color to white for visibility
                      '&.Mui-focused': {
                          color: 'white', // Ensure the label color remains white when focused
                      },
                  }}
              >
                  Firmware Type
              </InputLabel>
              <Select
                  labelId="firmware-select-label"
                  id="firmware-select"
                  value={firmware}
                  label="Firmware Type"
                  onChange={handleFirmwareChange}
                  sx={{
                      color: 'white', // Text color for the selected item
                      /*'& .MuiSelect-select': {
                          bgcolor: 'black', // Background color of the select
                          '&:focus': {
                              bgcolor: 'black', // Maintain the background color when the select is focused
                          },
                      },*/
                      '& .MuiOutlinedInput-notchedOutline': {
                          borderColor: 'white', // Border color of the select
                      },
                      '&:hover .MuiOutlinedInput-notchedOutline': {
                          borderColor: 'white', // Border hover color
                      },
                      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                          borderColor: 'white', // Border color when the select is focused
                      },
                      '& .MuiSvgIcon-root': {
                          color: 'white', // Color of the dropdown icon
                      },
                  }}
              >
                  <MenuItem value="base">EzloPi Core</MenuItem>
                  {/*<MenuItem value="led">EzloPi Addressable LED</MenuItem>*/}
              </Select>
          </FormControl>
          <List
              component="div"
              subheader={
                  <ListSubheader component="div" disableSticky>
                      Device Status
                  </ListSubheader>
              }
          >
              <SubMenuWrapper>
                  <List component="div">
                      <ListItem component="div">
                          { (ToggleForm.deviceStatus == true && ToggleForm.deviceFormSteps >= 0)
                              ?
                              (<>
                                  <Button disabled sx={{width: '88%'}}>
                                      <Alert sx={{width: '100%'}} severity="success">Connected </Alert>
                                  </Button>

                              </>)
                              :
                              (<>

                                  <Button

                                      disabled={true}
                                      disableRipple
                                      onClick={closeSidebar}
                                      startIcon={
                                          <FiberManualRecordIcon
                                              fontSize="small"
                                              sx={{
                                                  color: '#d9182e',
                                              }}
                                          />
                                      }
                                  >
                                      Disconnected
                                  </Button>
                              </>)}

                      </ListItem>


                  </List>
              </SubMenuWrapper>
          </List>

          {ToggleForm.deviceStatus == true ? (
              <List
                  component="div"
              >
                  <ListItem component="div"
                            sx={{

                                color:  (ToggleForm.deviceStatus == true)  ? '#ffffff' : '#d9182e'
                            }}
                  >
                      {
                          (ToggleForm.deviceStatus == true ) ? (
                              <TerminalIcon
                                  fontSize="small"
                                  sx={{
                                      mr: 1,
                                      color:'#4caf50',
                                  }}
                              />
                          ) : (
                              <TerminalIcon
                                  fontSize="small"
                                  sx={{
                                      mr: 1,
                                      color:'#d9182e',
                                  }}
                              />
                          )

                      }
                      <span>
                     <b>Switch to terminal</b>
                  </span>
                      <MaterialUISwitch
                          checked={checked}
                          onChange={handleChange}
                          inputProps={{ 'aria-label': 'controlled' }}
                      />
                  </ListItem>
              </List>
          ) : ('')}

          <List
              component="div"
          >
              <ListItem component="div"
                        sx={{

                      color: (ToggleForm.deviceStatus == true && ToggleForm.deviceFormSteps >= 0)  ? '#4caf50' : '#d9182e'
                  }}
                  >
                      {
                          (ToggleForm.deviceStatus == true && ToggleForm.deviceFormSteps >= 0) ? (
                              <GppGoodIcon
                                  fontSize="small"
                                  sx={{
                                      mr: 1,
                                      color:'#4caf50',
                                  }}
                              />
                          ) : (
                              <GppMaybeIcon
                                  fontSize="small"
                                  sx={{
                                      mr: 1,
                                      color:'#d9182e',
                                  }}
                              />
                          )
                      }
                  <span>
                     <b>Connect to a serial port</b>
                  </span>
                  {/*<Button
                      disableRipple
                      onClick={closeSidebar}
                      startIcon={
                          (ToggleForm.deviceStatus == true && ToggleForm.deviceFormSteps >= 0) ? (
                              <GppGoodIcon
                                  fontSize="small"
                                  sx={{
                                      mr: 1,
                                      color:'#4caf50',
                                  }}
                              />
                          ) : (
                              <GppMaybeIcon
                                  fontSize="small"
                                  sx={{
                                      mr: 1,
                                      color:'#d9182e',
                                  }}
                              />
                          )
              }
                      sx={{
                          color: (ToggleForm.deviceStatus == true && ToggleForm.deviceFormSteps >= 0)  ? '#4caf50' : '#d9182e'
                      }}
          >
                      Connect to a serial port
                  </Button>*/}
              </ListItem>
              <SubMenuWrapper>
                  <List component="div">
                      <ListItem component="div">
                          {ToggleForm.deviceStatus == false && (

                              <Button onClick={() => dispatch(isConnected(true))} sx={{width: '88%'}}>
                              <Alert sx={{width: '100%'}} icon={<BoltIcon fontSize="inherit" />} severity="info"> Connect Device </Alert>
                              </Button>
                          )}
                          {ToggleForm.deviceStatus == true && (

                              <Button onClick={() => dispatch(isDisconnected(true))} sx={{width: '88%'}}>
                              <Alert sx={{width: '100%'}} icon={<PowerSettingsNewIcon sx={{
                              color: '#d9182e'
                          }} fontSize="inherit" />}  severity="error">Disconnect </Alert>
                              </Button>
                          )}
                      </ListItem>
                      {/*<ListItem component="div">
                          <Button
                              variant="contained"
                              disableRipple
                              onClick={() => dispatch(isDisconnected(true))}
                              startIcon={<PowerSettingsNewIcon />}
                              disabled={ToggleForm.deviceStatus == false ? true : false }
                          >
                              Disconnect Device
                          </Button>
                      </ListItem>*/}

                  </List>
              </SubMenuWrapper>
          </List>



          <List
              component="div"
          >
              <ListItem component="div"
                        sx={{

                  color:  (ToggleForm.deviceStatus == true && ToggleForm.deviceFormSteps >= 1)  ? '#4caf50' : '#d9182e'
              }}>
                  {
                      (ToggleForm.deviceStatus == true && ToggleForm.deviceFormSteps >= 1) ? (
                          <GppGoodIcon
                              fontSize="small"
                              sx={{
                                  mr: 1,
                                  color:'#4caf50',
                              }}
                          />
                      ) : (
                          <GppMaybeIcon
                              fontSize="small"
                              sx={{
                                  mr: 1,
                                  color:'#d9182e',
                              }}
                          />
                      )

                  }
                  <span>
                     <b>Actions</b>
                  </span>
              </ListItem>
              <SubMenuWrapper>
                  <List component="div">
                      <ListItem component="div" sx={{
                          p: 4,
                          '& .MuiButtonGroup-root': {
                              boxShadow: theme.shadows[0], // Removes box-shadow
                              '& .MuiButton-root': {
                                  borderRadius: theme.shape.borderRadius, // Uses the theme's border radius
                                  borderColor: theme.palette.divider, // Uses the theme's divider color
                                  color: '#33C2FF', // Uses the theme's primary color
                                  backgroundColor: 'rgb(240, 250, 255)', // Uses the theme's paper color
                                  '&:hover': {
                                      backgroundColor: '#33C2FF',
                                      color: 'rgb(240, 250, 255)'
                                  },
                                  '&.Mui-disabled': {
                                      color: theme.palette.action.disabled, // Uses the theme's disabled color
                                      backgroundColor: theme.palette.action.disabledBackground, // Uses the theme's disabled background color
                                  },
                              },
                              '& .MuiButtonGroup-grouped:not(:last-child)': {
                                  borderRightColor: theme.palette.divider, // Ensures the divider color is consistent
                              },
                          },
                      }}>
                          <ButtonGroup
                              variant="contained"
                              fullWidth
                              aria-label="contained button group"
                              sx={{
                                  width: '70%',
                                  marginLeft: '25px',
                                  padding: '5px',
                                  marginTop: '5px',
                                  marginBottom: '3px',
                                  backgroundColor: 'rgb(240, 250, 255)',
                                  color: '#33C2FF',
                                  borderRadius: '10px',
                                  '.MuiButton-root': {
                                      color: '#33C2FF !important',
                                      textTransform: 'none',
                                      fontSize: '0.875rem',
                                      fontWeight: '500',
                                      boxShadow: 'none',
                                      '&:hover': {
                                          color: 'rgb(220, 240, 255) !important',
                                          boxShadow: 'none',
                                      },
                                      '&:first-of-type': {
                                          borderTopLeftRadius: '10px',
                                          borderBottomLeftRadius: '10px',
                                      },
                                      '&:last-child': {
                                          borderTopRightRadius: '10px',
                                          borderBottomRightRadius: '10px',
                                      }
                                  },
                                  '.MuiButtonGroup-grouped:not(:last-child)': {
                                      borderRight: '1px solid rgba(0, 0, 0, 0.12)', // Separator border between buttons
                                  },
                                  '.MuiButtonGroup-grouped': {
                                      borderColor: 'rgba(0, 0, 0, 0.12)', // Border color around the buttons
                                  },
                              }}
                          >
                              <Button
                                  onClick={() => dispatch(isDeviceInfo(true))}
                                  disabled={ToggleForm.deviceStatus === false || deviceCMD != null}
                                  startIcon={<InfoIcon/>}
                              >
                                  Info {ToggleForm.deviceInfoLoading === true || ToggleForm.deviceCMD === 'getInfo' ? (
                                  <CircularProgress size="1rem" sx={{ ml: 1 }} />
                              ) : null}
                              </Button>

                              <Button
                                  onClick={() => dispatch(deviceGetConfig(true))}
                                  disabled={ToggleForm.deviceStatus === false || deviceCMD != null}
                                  startIcon={<SettingsSuggestIcon/>}
                              >
                                  Config {ToggleForm.deviceConfigLoading === true || ToggleForm.deviceCMD === 'getConfig' ? (
                                  <CircularProgress size="1rem" sx={{ ml: 1 }} />
                              ) : null}
                              </Button>
                          </ButtonGroup>

                      </ListItem>
                      <ListItem component="div">
                          <Button
                              sx={{
                                  width: '88%',
                                  backgroundColor: theme => theme.palette.action.disabledBackground,
                                  color: theme => theme.palette.action.disabled,
                                  '&:hover': {
                                      backgroundColor: theme => theme.palette.action.disabledBackground,
                                  },
                              }}
                              onClick={() => dispatch(isFactorReset(true))}
                              disabled={!ToggleForm.deviceStatus || (deviceCMD != null && ToggleForm.deviceMessage !== "Cloud Api Error: Controller not found") || ezloFirmware}

                          >
                              <Alert sx={{width: '100%'}} icon={<SettingsBackupRestoreIcon fontSize="inherit" />} severity="info">Factory Reset
                                  {
                                      ToggleForm.factorResetLoading == true  || ToggleForm.deviceCMD === 'factorreset'   ? (
                                          <CircularProgress size="1rem" sx={{ml:0.1}} />
                                      ) : null
                                  }
                              </Alert>
                          </Button>

                      </ListItem>
                      <ListItem component="div">
                          <Button
                              sx={{
                                  width: '88%',
                                  backgroundColor: theme => theme.palette.action.disabledBackground,
                                  color: theme => theme.palette.action.disabled,
                                  '&:hover': {
                                      backgroundColor: theme => theme.palette.action.disabledBackground,
                                  },
                              }}
                              onClick={() => dispatch(isReset(true))}
                              disabled={ToggleForm.deviceStatus == false ? true : false || deviceCMD != null || ezloFirmware == true}

                          >
                              <Alert sx={{width: '100%'}} icon={<RestartAltIcon fontSize="inherit" />} severity="info"> Reboot
                                  {
                                      ToggleForm.resetLoading == true  || ToggleForm.deviceCMD === 'reset'   ? (
                                          <CircularProgress size="1rem" sx={{ml:0.1}} />
                                      ) : null
                                  }
                              </Alert>
                          </Button>

                      </ListItem>
                  </List>
              </SubMenuWrapper>
          </List>
          <List
              component="div"
          >
              <ListItem component="div"
                        sx={{

                            color:  (ToggleForm.deviceStatus == true && ToggleForm.deviceFormSteps >= 2)  ? '#4caf50' : '#d9182e'
                        }}
              >
                  {
                      (ToggleForm.deviceStatus == true && ToggleForm.deviceFormSteps >= 2) ? (
                          <GppGoodIcon
                              fontSize="small"
                              sx={{
                                  mr: 1,
                                  color:'#4caf50',
                              }}
                          />
                      ) : (
                          <GppMaybeIcon
                              fontSize="small"
                              sx={{
                                  mr: 1,
                                  color:'#d9182e',
                              }}
                          />
                      )

                  }
                  <span>
                     <b>Ready to flash</b>
                  </span>

              </ListItem>
              <SubMenuWrapper>
                  <List component="div">
                      <ListItem component="div">
                          <Button
                              sx={{width: '88%'}}
                              onClick={flashButtonClick}
                              disabled={ToggleForm.deviceStatus == false || (deviceCMD != null && ToggleForm.deviceCMD != 'flash' )}

                          >
                              <Alert sx={{width: '100%'}} icon={<AutoDeleteIcon fontSize="inherit" />} severity="info"> Flash Device
                                  {
                                      ToggleForm.deviceFlashLoading == true || ToggleForm.deviceCMD === 'flash'    ? (
                                          <CircularProgress size="1rem" sx={{ml:1}}/>
                                      ) : null
                                  }
                              </Alert>
                          </Button>

                      </ListItem>



                  </List>
              </SubMenuWrapper>
          </List>

      </MenuWrapper>
    </>
  );
}

export default SidebarMenu;
